<template>
  <div class="map" v-loading="loading">
      <el-dialog
          v-loading="loading"
          :visible="viewModal"
          :before-close="closeModal"
          :close-on-click-modal="false"
          width="80%"
          class="modal"
          >
          <router-view></router-view>
      </el-dialog>
      <el-dialog
          v-loading="loading"
          :visible="listModal"
          :before-close="closeListModal"
          :close-on-click-modal="false"
          width="50%"
          class="modal"
          >
          <el-row v-if="modalImages">
              <el-col :md="6" :sm="4" :xs="8"
                  v-for="(asset,index) in modalImages"
                  :key="index"
                  >
                  <div class="artwork" @click="loadModal(asset.id)">
                      <el-image
                          v-if="asset.filetype == 'image'" 
                          class="artwork"
                          :src="asset.image_blob"
                          fit="cover"
                          lazy
                          ></el-image>
                      <el-image
                          v-if="asset.filetype == 'video'" 
                          class="artwork"
                          :src="asset.video_thumb"
                          fit="cover"
                          lazy
                          ></el-image>
                  </div>
              </el-col>
          </el-row>
          <div class="pagination">
              <el-pagination
                  layout="prev, pager, next"
                  :page-size="12"
                  :total="modalTotalImages"
                  @current-change="pagination"
                  >
              </el-pagination>
          </div>
      </el-dialog>
      <el-card class="filters">
          <el-collapse v-model="activeCollapse" accordion>
              <h5>Showing</h5>
              {{ this.totalArtwork }} Artworks <br/><br/>
              <hr/>
              <el-collapse-item name="1">
                  <template slot="title">
                      <div>
                          <filterIcon/>
                          Filters 
                      </div>
                  </template>
                  <h5>Type</h5>
                  <el-checkbox-group class="checkbox-list" v-model="mapFilters.filetype" @change="setFiltersFile">
                      <el-checkbox class="checkbox-label" v-for="filetype in filetypes" :label="filetype" :key="filetype">
                          <component :is="(filetype === 'image') ? 'imageIcon' : 'videoIcon'" />
                          {{ filetype }}
                      </el-checkbox>
                  </el-checkbox-group>
                  <h5>Charities</h5>
                  <el-checkbox-group class="checkbox-list" v-model="mapFilters.charity" @change="setFiltersCharity">
                      <el-checkbox class="checkbox-label" v-for="cha in charities" :label="cha.id" :key="cha.id">
                          {{ cha.name }}
                      </el-checkbox>
                  </el-checkbox-group>
                  <h5>Emotions</h5>
                  <el-checkbox-group class="checkbox-list" v-model="mapFilters.mood" @change="setFiltersMood">
                      <el-checkbox class="checkbox-label" v-for="mood in mood" :label="mood" :key="mood">
                          <component :is="mood" />
                          {{ mood }}
                      </el-checkbox>
                  </el-checkbox-group>
                  <h5>Ratings</h5>
                  <el-checkbox-group class="checkbox-list" v-model="mapFilters.rating" @change="setFiltersRating">
                      <el-checkbox class="checkbox-label" v-for="rating in ratings" :label="rating.value" :key="rating.label">
                          {{ rating.label }}
                      </el-checkbox>
                  </el-checkbox-group>
              </el-collapse-item>
          </el-collapse>
      </el-card>
      <GmapMap 
          v-if="points"
          ref="map"
          class="map"
          :options="mapOptions"
          :center="this.center"
          :zoom="zoomLevel"
          map-type-id="terrain"
          @bounds_changed="onBoundsChanged($event, true)"
          >
      </GmapMap>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapState, mapGetters, mapMutations, mapActions } from "vuex"

import GmapCustomMarker from 'vue2-gmap-custom-marker';

import filterIcon from "@/assets/filter/filter.svg"
import videoIcon from "@/assets/filter/video.svg"
import imageIcon from "@/assets/filter/image.svg"

import excited from "@/assets/mood/excited.svg"
import happy from "@/assets/mood/happy.svg"
import surprised from "@/assets/mood/surprised.svg"
import bored from "@/assets/mood/bored.svg"
import sad from "@/assets/mood/sad.svg"
import angry from "@/assets/mood/angry.svg"

/* VUE2-GOOGLE-MAPS
** https://www.npmjs.com/package/vue2-google-maps
** https://cloud.google.com/maps-platform/
*/
import * as VueGoogleMaps from "vue2-google-maps"
import GmapCluster from 'vue2-google-maps/dist/components/cluster'
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { gmapApi } from 'vue2-google-maps'
import mapStyles from '@/assets/mapStyles.json'
import { debounce } from 'lodash'

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLEMAPS_API_KEY,
    libraries: 'geometry,maps'
  }
});

Vue.component('cluster', GmapCluster)

export default {

  components: {
    GmapCustomMarker,
    filterIcon,
    videoIcon,
    imageIcon,
    excited,
    happy,
    surprised,
    bored,
    sad,
    angry
  },

  data() {
    return {
      activeCollapse: null,
      filetypes: ['image'],
      ratings: [
        { value: 1, label: '1 Heart' },
        { value: 2, label: '2 Hearts' },
        { value: 3, label: '3 Hearts' },
        { value: 4, label: '4 Hearts' },
        { value: 5, label: '5 Hearts' }
      ],
      center: { lat: 41.77686636049381, lng: -75.0209762625 },
      zoomLevel: 2,
      mapOptions: {
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUI: true,
        draggable: true,
        styles: mapStyles,
        minZoom: 2,
      },
      currentLocation: {},
      existingMarkers: {},
    }
  },

  mounted() {
    this.getAllCharities();
    this.getUserLocation();

    setTimeout(() => {
      this.handleFetchPoints();
    }, 1000);
  },

  watch: {
    $route: {
      immediate: true,
      handler: function () {
        if (this.$route.params.assetId) {
          this.getAssetDetails(this.$route.params.assetId)
          this.openViewModal()
        } else {
          this.closeViewModal()
        }
      }
    },
  },

  computed: {
    google: gmapApi,
    ...mapState([
      'loading',
      'viewModal',
      'listModal',
      'points',
      'mood',
      'totalArtwork',
      'mapFilters',
      'modalImages',
      'modalTotalImages',
      "charities"
    ]),
    ...mapGetters([
      'filterPoints'
    ])
  },

  methods: {
    ...mapMutations([
      'openViewModal',
      'closeViewModal',
      'openListModal',
      'closeListModal',
      'setAsset',
      'setFileFilters',
      'setCharityFilters',
      'setMoodFilters',
      'setRatingFilters',
      "setCharities"
    ]),

    ...mapActions([
      'fetchPoints',
      'getAssetDetails',
      'fetchLocationArtwork',
      "fetchCharities"
    ]),

    setFiltersFile: function (values) {
      this.setFileFilters(values)
      this.handleFetchPoints(true)
    },

    setFiltersCharity: function (values) {
      this.setCharityFilters(values)
      this.handleFetchPoints(true)
    },

    setFiltersMood: function (values) {
      this.setMoodFilters(values)
      this.handleFetchPoints(true)
    },

    setFiltersRating: function (values) {
      this.setRatingFilters(values)
      this.handleFetchPoints(true)
    },

    getLocationArtwork: function (asset) {
      this.fetchLocationArtwork({ lat: asset.lat, lng: asset.lng, page: 1 })
      this.currentLocation = { lat: asset.lat, lng: asset.lng }
      this.openListModal()
      this.onBoundsChanged(null, true)
    },

    pagination: function (page) {
      let payload = this.currentLocation
      payload.page = page
      this.fetchLocationArtwork(payload)
      this.onBoundsChanged(null, true)
    },

    closeModal: function () {
      this.$router.push({ path: `/map` })
      this.setAsset({})
      this.closeViewModal()
    },

    loadModal: function (id) {
      this.$router.push({ path: `/map/${id}` })
      // this.closeListModal()
    },

    addMarkerClickListener: function (marker, asset) {
      marker.addListener('click', () => {
        if (asset.count > 1) {
          this.getLocationArtwork(asset);
        } else {
          this.$router.push({ path: `/map/${asset.id}` })
        }
      });
    },

    getAllCharities: function () {
      this.fetchCharities(this.page)
        .then(() => {
          this.setCharities(this.charities)
        });
    },

    getUserLocation: function () {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            this.center = { lat: latitude, lng: longitude };
            this.zoomLevel = 2;
          },
          (error) => {
            console.error(error);
            this.center = { lat: 41.77686636049381, lng: -75.0209762625 };
          }
        );
      }
    },

    onBoundsChanged: debounce(function (event, clearCluster) {
      if (this.$refs.map && this.$refs.map.$mapObject && this.$refs.map.$mapObject.getBounds()) {
        const map = this.$refs.map.$mapObject;
        const bounds = map.getBounds();
        const ne = bounds.getNorthEast();
        const sw = bounds.getSouthWest();
        const center = map.getCenter();
        const centerLat = center.lat();
        const centerLng = center.lng();
        const radius = this.haversine(ne.lat(), ne.lng(), sw.lat(), sw.lng());

        const params = {
          latitude: centerLat,
          longitude: centerLng,
          radius,
        };

        if (clearCluster) {
          this.addMarkersToVisibleArea(this.points, sw, ne);
        } else {
          this.fetchPoints(params).then((result) => {
            if (result) {
              this.addMarkersToVisibleArea(this.points, sw, ne);
            }
          });
        }
      }
    }, 500),

    addMarkersToVisibleArea(points, sw, ne) {
      // Filter the points that are within the visible area (bounds)
      const visiblePoints = points.filter(point =>
        point.lat >= sw.lat() && point.lat <= ne.lat() &&
        point.lng >= sw.lng() && point.lng <= ne.lng()
      );

      if (visiblePoints.length === 0) {
        this.initMapCluster(points);
      } else {
        this.initMapCluster(visiblePoints);
      }
    },

    initMapCluster: function (points) {
      if (this.$refs.map && this.$refs.map.$mapPromise) {
        this.$refs.map.$mapPromise.then(() => {
          const google = this.google;
          const map = this.$refs.map.$mapObject;
          const markers = [];

          // Create a list of markers for the visible points
          points.forEach((point) => {
            let marker = this.existingMarkers[point.id];

            if (!marker) {
              const image = {
                url: require("@/assets/marker.png"),
                labelOrigin: new this.google.maps.Point(0, 50),
              };

              const label = point.count > 1 ? {
                text: "" + point.count,
                className: "counterMarker",
                color: "white",
              } : undefined;

              marker = new google.maps.Marker({
                position: { lat: point.lat, lng: point.lng },
                icon: point.count > 1 ? image : require("@/assets/marker.png"),
                label: label,
              });

              this.addMarkerClickListener(marker, point);

              // Store the marker reference for future updates
              this.existingMarkers[point.id] = marker;
            }

            markers.push(marker);
          });

          // Now, update the marker cluster with the new markers
          if (this.cluster) {
            this.cluster.clearMarkers();
            this.cluster.addMarkers(markers);
          } else {
            const clusterImage = {
              url: require("@/assets/marker.png"),
              labelOrigin: new google.maps.Point(0, 50),
            };

            const clusterLabelLarge = function (length) {
              return {
                text: "" + length,
                className: "counterClusterMarkerLarge",
                color: "white",
              };
            };

            const clusterLabelSmall = function (length) {
              return {
                text: "" + length,
                className: "counterClusterMarkerSmall",
                color: "white",
              };
            };

            const algorithmOptions = {
              maxZoom: 15,
            };

            const markerClustererOptions = {
              map: map,
              markers: markers,
              renderer: {
                render: function (cluster, clusterStatus) {
                  const mean = clusterStatus.clusters.markers.mean;
                  let label;

                  if (mean <= cluster.count) {
                    label = clusterLabelLarge(cluster.count);
                  } else {
                    label = clusterLabelSmall(cluster.count);
                  }

                  return new google.maps.Marker({
                    position: cluster.position,
                    label: label,
                    icon: clusterImage,
                  });
                },
              },
              algorithmOptions: algorithmOptions,
            };

            this.cluster = new MarkerClusterer(markerClustererOptions);
          }
        });
      }
    },



    handleFetchPoints: function (clearCluster = false) {
      this.onBoundsChanged(null, clearCluster);
    },

    haversine: function (lat1, lon1, lat2, lon2) {
      const R = 6371; // Radius of the Earth in kilometers
      const toRad = (deg) => deg * (Math.PI / 180); // Convert degrees to radians

      const lat1Rad = toRad(lat1);
      const lon1Rad = toRad(lon1);
      const lat2Rad = toRad(lat2);
      const lon2Rad = toRad(lon2);

      const dlat = lat2Rad - lat1Rad;
      const dlon = lon2Rad - lon1Rad;

      const a =
        Math.sin(dlat / 2) * Math.sin(dlat / 2) +
        Math.cos(lat1Rad) * Math.cos(lat2Rad) *
        Math.sin(dlon / 2) * Math.sin(dlon / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

      const distance = R * c; // Distance in kilometers
      return distance; // You can multiply by 1000 to get meters
    },
  }
}
</script>
